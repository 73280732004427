<template>
  <div>
    <List
      :fields="fields"
      modelName="Grupo"
      create_url="core_group_create"
      backend_url="core/group"
      model="core_group"
      :is_paginated="true"
    ></List>
  </div>
</template>

<script>
import List from "@/view/pages/crud/List";

export default {
  name: "GroupList",
  components: { List },
  data() {
    return {
      fields: [
        { key: "name", label: "Nombre" },
        { key: "actions", label: "" },
      ],
    };
  },
};
</script>

<style scoped></style>
